<template>
  <div class="PageNotFound">
    <div
    class="d-flex flex-column align-center content"
  >
    <span class="font-1-extra-large">404</span>
    <p class="font-1-large">Page introuvable</p>
  </div>
  </div>
</template>


<script>
export default {
  name: "PageNotFound",
};
</script>

<style lang="scss">
@import "../style";
</style>