<template>
    <div id="app">
        <v-app>
            <v-list two-line>
                <InfiniteScroll :items="users" :lastPage="lastPage" @refetch="fetch">
                    <template v-slot:item="{ item }">
                        <v-list-item
                            ><v-badge
                                left
                                :content="item.num"
                                :value="item.num"
                                color="red"
                                offset-x="30"
                                offset-y="30"
                            >
                                <v-list-item-avatar size="60" class="d-flex">
                                    <v-img
                                        :src="item.avatarURL"
                                        lazy-src="/assets/images/profil-question.png"
                                    ></v-img>
                                </v-list-item-avatar>
                            </v-badge>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    item.username
                                }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content class="disableMobile">
                                <v-list-item-title class="d-flex justify-center"
                                    >MESSAGE</v-list-item-title
                                >
                                <v-list-item-title
                                    class="d-flex justify-center"
                                    >{{ item.message }}</v-list-item-title
                                >
                            </v-list-item-content>
                            <v-list-item-content>
                                <v-list-item-title class="d-flex justify-center"
                                    >NIVEAU</v-list-item-title
                                >
                                <v-list-item-title
                                    class="d-flex justify-center"
                                    >{{ item.level }}</v-list-item-title
                                >
                            </v-list-item-content>
                            <v-progress-circular
                                color="red darken-2"
                                rounded
                                size="60"
                                :value="(item.xp / item.xp_max) * 100"
                                rotate="-90"
                                >{{
                                    Math.round((item.xp / item.xp_max) * 100)
                                }}%</v-progress-circular
                            >
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </InfiniteScroll>
            </v-list>
        </v-app>
    </div>
</template>

<script>
import axios from "axios";
import InfiniteScroll from "@/components/InfiniteScroll";
export default {
    name: "App",

    components: {
        InfiniteScroll,
    },
    data() {
        return {
            users: [],
            lastPage: 1,
        };
    },
    methods: {
        async fetch(page, done) {
            let urlAPI = process.env.VUE_APP_API_URL;
            let users = await axios.get(`${urlAPI}/users?page=${page}`);
            this.users.push(...users.data.users);
            this.lastPage = users.data.lastPage;
            done();
        },
    },
    mounted() {
        //this.fetch(1, () => {});
    },
};
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {
    .disableMobile {
        display: none;
        width: 0;
        height: 0;
        opacity: 0;
        visibility: collapse;
    }
}
</style>
