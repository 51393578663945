<template>
  <div class="cgu background">
    <h1 class="text-center">Condition général d'utilisation</h1>
    <v-divider style="margin-bottom: 20px"></v-divider>
    <div v-for="article in articles" :key="article.num">
      <h2 class="text-center">
        ARTICLE {{ article.num }}: {{ article.title }}
      </h2>
      <p v-for="content in article.contents" :key="content">{{ content }}</p>
    </div>
    <h3>Date de dernière mise à jour: 25/07/2021</h3>
  </div>
</template>

<script>
export default {
  name: "Rules",

  data: () => ({
    articles: [
      {
        num: 1,
        title: "INFORMATIONS LEGALES",
        contents: [
          "Le site discord-lafamilia.fr est hébergé par: CLOUDFLARE FRANCE SAS, dont le siège est situé à l'adresse suivante : 6 Place de la Madeleine, Paris 75008 et ‌PULSEHEBERG SASU, dont le siège est situé à l'adresse suivante : 55, Avenue des Champs Pierreux, Nanterre 92012.",
        ],
      },
      {
        num: 2,
        title: "DECLARATION A LA CNIL",
        contents: [
          "Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel) relative à l'informatique, aux fichiers et aux libertés, ce site n'a pas fait l'objet d'une déclaration auprès de la Commission nationale de l'informatique et des libertés (www.cnil.fr). ",
        ],
      },
      {
        num: 3,
        title: "CONTACT",
        contents: [
          "Pour toute question ou demande d'information concernant le site, ou tout signalement de contenu ou d'activités illicites, l'utilisateur peut contacter l'éditeur à l'adresse e-mail suivante : mail@discord-lafamilia.fr .",
        ],
      },
      {
        num: 4,
        title: "ACCEPTATION DES CONDITIONS",
        contents: [
          "L'accès et l'utilisation du site sont soumis à l'acceptation et au respect des présentes conditions générales d'utilisation. L'éditeur se réserve le droit de modifier, à tout moment et sans préavis, le site et les présentes conditions générales d'utilisation, notamment pour s'adapter aux évolutions du site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.Il est donc conseillé à l'utilisateur de se référer avant toute navigation à la dernière version des conditions générales d'utilisation, accessible à tout moment sur le site. En cas de désaccord avec les conditions générales d'utilisation, aucun usage du site ne saurait être effectué par l'utilisateur.",
        ],
      },
      {
        num: 5,
        title: "ACCES ET NAVIGATION",
        contents: ["L'éditeur met en oeuvre les solutions techniques à sa disposition pour permettre l'accès au site 24 heures sur 24, 7 jours sur 7. Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l'accès au site ou à certaines pages de celui-ci afin de procéder à des mises à jour, des modifications de son contenu ou tout autre action jugée nécessaire au bon fonctionnement du site.",
        "La connexion et la navigation sur le site discord-lafamilia.fr valent l'acceptation sans réserve des présentes conditions générales d'utilisation, quels que soient les moyens techniques d'accès et les terminaux utilisés.",
        "Les présentes conditions générales d'utilisation s'appliquent, en tant que de besoin, à toute déclinaison ou extension du site sur les réseaux sociaux et/ou communautaires."],
      },
      {
        num: 6,
        title: "GESTION DU SITE",
        contents: [
          "Pour la bonne gestion du site, l'éditeur pourra à tout moment :",
          "- Suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou a certaines parties du site, à une catégorie déterminée d'internaute(s) ;",
          "- Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales en vigueur ;",
          "- Suspendre le site afin de procéder à des mises à jour.",
        ],
      },
      {
        num: 7,
        title: "RESPONSABILITES",
        contents: [
          "L'éditeur n'est responsable que du contenu qu'il a lui-même édité.",
          "L'éditeur n'est pas responsable :",
          "- En cas de problématique ou défaillance techniques, informatiques ou de compatibilité du site avec un matériel ou logiciel quel qu'il soit ;",
          "- Des dommages directs ou indirects, matériels ou immatériels, prévisibles ou imprévisibles résultant de l'utilisation ou des difficultés d'utilisation du site ou des services ;",
          "- Des caractéristiques intrinsèques de l'internet, notamment celles relatives au manque de fiabilité et au défaut de sécurisation des informations y circulant ;",
          "- Des contenus ou activités illicites utilisant son site et ce, sans qu'il en ait pris dûment connaissance.",
          "Par ailleurs, le site ne saurait garantir l'exactitude, la complétude, et l'actualité des informations qui y sont diffusées.",
          "L'utilisateur est responsable :",
          "- De la protection de son matériel et de ses données ;",
          "- De l'utilisation qu'il fait du site ou de ses services ;",
          "- S'il ne respecte pas les présentes conditions générales d'utilisation.",
        ],
      },
      {
        num: 8,
        title: "LIENS HYPERTEXTES",
        contents: [
          "Le site peut contenir des liens hypertextes pointant vers d'autres sites internet sur lesquels discord-lafamilia.fr n'exerce pas de contrôle. Malgré les vérifications préalables et régulières réalisées par l'éditeur, celui-ci décline toute responsabilité quant aux contenus qu'il est possible de trouver sur ces sites.",
          "L'éditeur autorise la mise en place de liens hypertextes vers toute page ou document de son site sous réserve que la mise en place de ces liens ne soit pas réalisée à des fins commerciales ou publicitaires",
          "En outre, l'information préalable de l'éditeur du site est nécessaire avant toute mise en place de lien hypertexte.",
          "Sont exclus de cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique, pornographique, xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre.",
          "Enfin, discord-lafamilia.fr se réserve le droit de faire supprimer à tout moment un lien hypertexte pointant vers son site, si l'éditeur l'estime non conforme à sa politique éditoriale.",
        ],
      },
      {
        num: 9,
        title: "PROPRIETE INTELLECTUELLE",
        contents: [
          "La structuration du site mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications informatiques qui le composent sont la propriété de l'éditeur et sont protégés comme tels par les lois en vigueur en Europe. Et ce, à l'exception des éléments expressément désignés comme libres de droits sur le site ou par l'éditeur. L'accès au site ne vaut pas reconnaissance d'un droit et, de manière générale, ne confère aucun droit de propriété intellectuelle relatif à un élément du site, lesquels restent la propriété exclusive de l'éditeur. Il est interdit à l'utilisateur d'introduire des données sur le site qui modifieraient ou qui seraient susceptibles d'en modifier le contenu ou l'apparence.",
        ],
      },
      
    ],
  }),
};
</script>

<style lang="scss">
.cgu {
  margin: 0px auto;
  padding: 5%;
  padding-top: 30px;
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  h2 {
    padding-bottom: 10px;
    padding-top: 15px;
  }
  p {
    text-align: justify;
  }
}

@media only screen and (min-width: 960px) {
  .cgu {
    margin-left: 256px;
    h1 {
      font-size: 3em;
      margin-bottom: 20px;
    }
  }
}
</style>
