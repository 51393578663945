import Vue from 'vue';
import VueRouter from 'vue-router';
import Classement from '../views/Classement.vue';
//import Home from '../views/Home.vue';
import PageNotFound from '../views/PageNotFound.vue';
import Rules from '../views/Rules.vue';
import CGU from '../views/CGU';
import RGPD from '../views/RGPD';
import LegalInformation from '../views/LegalInformation';

Vue.use(VueRouter)

const routes = [
  {
    path: '/classement',
    name: 'Classement',
    component: Classement
  },
  {
    path: '/regles',
    name: 'Règles',
    component: Rules,
  },
  {
    path: '/cgu',
    name: 'Condition général d\'utilisation',
    component: CGU,
  },
  {
    path: '/rgpd',
    name: 'Politique de confidentialité',
    component: RGPD,
  },
  {
    path: '/mentions-legales',
    name: "Mentions légales",
    component: LegalInformation,
  },
  {
    path: '/404',
    alias: "*",
    name: 'Page introuvable',
    component: PageNotFound,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
