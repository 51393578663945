<template>
    <div>
        <div class="list" v-for="item in items" :key="item.num">
            <slot name="item" v-bind:item="item" />
        </div>
        <infinite-loading @infinite="handleScrolledToBottom"></infinite-loading>
    </div>
</template>

<script>
export default {
    name: "InfiniteScroll",
    props: {
        items: {
            required: true,
            type: Array,
        },
        lastPage: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            page: 0,
        };
    },
    methods: {
        async handleScrolledToBottom($state) {
            if (this.page >= this.lastPage) {
                $state.complete();
            } else {
                this.page++;
                this.$emit("refetch", this.page, () => {
                    $state.loaded();
                });
            }
        },
    },
};
</script>

<style lang="scss">

.list{
    width: 90%;
    margin: 0px auto;
    
}

@media only screen and (max-width: 600px) {
    .list{
    width: 100%;
    }  
}
@media only screen and (min-width: 960px) {
    .list{
    width: calc(70% - 256px);
    margin-left: calc(256px + 15% ) ;
    }
} 


</style>
