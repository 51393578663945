<template>
    <div class="footer" style="height:80px">
        <v-footer class="footerColor" padless>
            <v-col
                cols="12"
                sm="8"
                md="4"
                class="title-link"
                >Liens utiles:
                <a class="link" v-for="link in links"
                :key="link.title" :href="link.route"><br>
                {{link.title}}</a>
                
                </v-col
            >
            <v-col
                cols="12"
                sm="8"
                md="4"
                class="title-link"
                >Sécurité:
                <a class="link" v-for="security in securitys"
                :key="security.title" :href="security.route"><br>
                {{security.title}}</a>
                </v-col
            >
            <v-col class="text-center" style="margin-bottom: 30px;" cols="12"> <v-divider style="margin-bottom: 30px;"></v-divider><span> © 2021 | LaFamilia </span></v-col>
        </v-footer>
    </div>
</template>

<script>
export default {
    name: "Footer",

    data: () => ({
        links: [
            {
                title: "Classement",
                route: "/classement",
            }
        ],
        securitys: [
            {
                title: "Condition généales d'utilisation",
                route: "/cgu",
            },
            {
                title: "Politique de confidentialité",
                route: "/rgpd",
            },
            {
                title: "Mentions légales",
                route: "/mentions-legales",
            },
        ],
    }),

};
</script>

<style lang="scss">

.footer {
    
    margin: 0px auto;
    .link {
            padding-top: 0px;
            padding-bottom: 0px;
            color: gray;
        }
}

@media only screen and (min-width: 960px) {
    .footer {
        margin-left: 256px;
        .title-link {
            margin-left: 50px;
            margin-right: 50px;
            padding-top: px;
        }
        .link {
            padding-top: 0px;
            padding-bottom: 0px;
            color: gray;
        }
    }
}
</style>
