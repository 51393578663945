<template>
  <div id="NavBar">
    <v-app-bar app fixed height="60px" class="menu">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="!$vuetify.breakpoint.mdAndUp"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>Pokedex</v-toolbar-title>
      <v-spacer></v-spacer
      ><!--

            <v-btn icon @click="darkMode">
                <v-icon>mdi-invert-colors</v-icon>
            </v-btn>-->
    </v-app-bar>

    <v-navigation-drawer
      class="menu"
      style="position: fixed"
      v-model="drawer"
      absolute
      :permanent="$vuetify.breakpoint.mdAndUp"
    >
      <v-list dense link temporary>
        <v-divider style="margin-bottom: 4px; margin-top: 52px"></v-divider>
        <v-list-item v-for="item in items" :key="item.title" :to="item.route">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!--
    <v-snackbar v-model="snackbar"  timeout=0 >
        <div class="text-justify" >Des cookies sont utilisés pour faciliter la navigation sur le site. En poursuivant la navigation sur discord-lafamilia.fr vous en acceptez l'utilisation.</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar> -->
  </div>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  data: () => ({
      snackbar: true,
    drawer: false,
    group: null,
    items: [
      {
        title: "Classement",
        icon: "mdi-account-group",
        route: "/classement",
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" module>
.title {
  padding: auto;
}
</style>
