<template>
  <div class="legal background">
    <h1 class="text-center">Mentions légales</h1>
    <v-divider style="margin-bottom: 20px"></v-divider>
    <div v-for="article in articles" :key="article.num">
      <h2 class="text-center">
        {{ article.title }}
      </h2>
      <p v-for="content in article.contents" :key="content">{{ content }}</p>
    </div>
    <h3>Date de dernière mise à jour: 25/07/2021</h3>
  </div>
</template>

<script>
export default {
  name: "Legal Information",

  data: () => ({
    articles: [
      {
        num: 1,
        title: "POUR ENTRER EN CONTACT AVEC L'EDITEUR DU SITE",
        contents: [
          "Adresse email : mail@discord-lafamilia.fr",
        ],
      },
      {
        num: 2,
        title: "HEBERGEUR DU SITE",
        contents: [
          "Le site discord-lafamilia.fr est hébergé par: CLOUDFLARE FRANCE SAS, dont le siège est situé à l'adresse suivante : 6 Place de la Madeleine, Paris 75008 et ‌PULSEHEBERG SASU, dont le siège est situé à l'adresse suivante : 55, Avenue des Champs Pierreux, Nanterre 92012.",
        ],
      },
      
      
    ],
  }),
};
</script>

<style lang="scss">
.legal {
  margin: 0px auto;
  padding: 5%;
  padding-top: 30px;
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  h2 {
    padding-bottom: 10px;
    padding-top: 15px;
  }
  p {
    text-align: justify;
  }
}

@media only screen and (min-width: 960px) {
  .legal {
    margin-left: 256px;
    h1 {
      font-size: 3em;
      margin-bottom: 20px;
    }
  }
}
</style>
