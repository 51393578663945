<template>
    <div class="rules">
        <h1>Règlement</h1>
        <p>
            Bientôt disponible
        </p>
    </div>
</template>

<script>
export default {
    name: "Rules",

    
};
</script>

<style lang="scss">
.rules {
    margin: 0px auto;
    padding: 5%;
}

@media only screen and (min-width: 960px) {
    .rules {
        margin-left: 256px;
    }
}
</style>
