import Vue from 'vue'
import App from './App.vue'
import InfiniteLoading from 'vue-infinite-loading';
import vuetify from './plugins/vuetify'
import router from './router'

Vue.use(InfiniteLoading, { /* options */ });

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
