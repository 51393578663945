<template>
  <div class="cgu background">
    <h1 class="text-center">Politique de confidentialité</h1>
    <v-divider style="margin-bottom: 20px"></v-divider>
    <div v-for="article in articles" :key="article.num">
      <h2 class="text-center">
        ARTICLE {{ article.num }}: {{ article.title }}
      </h2>
      <p v-for="content in article.contents" :key="content">{{ content }}</p>
    </div>
    <h3>Date de dernière mise à jour: 25/07/2021</h3>
  </div>
</template>

<script>
export default {
  name: "RGPD",

  data: () => ({
    articles: [
      {
        num: 1,
        title: "COLLECTE DE VOS INFORMATIONS",
        contents: [
          "Vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet discord-lafamilia.fr d'après la définition de la CNIL : https://www.cnil.fr/fr/definition/donnee-personnelle.",
          "Les seules informations non personnelles récupérées correspondent à des informations transmises par l'application Discord à travers des bots présents sur le serveur discord lafamilia."
        ],
      },
      {
        num: 2,
        title: "UTILISATION DE VOS INFORMATIONS",
        contents: [
          "Toutes les informations que nous recueillons de votre part peuvent être utilisées pour :",
          "Collecter et établir des statistiques;",
          "Personnaliser votre expérience et répondre à vos besoins individuels."
        ],
      },
      {
        num: 3,
        title: "CONFIDENTIALITE DE VOS DONNEES",
        contents: [
          "Nous sommes les seuls propriétaires des informations recueillies sur notre site. Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société pour n’importe quelle raison, sans votre consentement.",
        ],
      },
      {
        num: 4,
        title: "DIVULGATION A DES TIERS",
        contents: [
          "Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierces parties de confiance qui nous aident à exploiter notre site web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.",
        ],
      },
      {
        num: 5,
        title: "PROTECTION DE VOS INFORMATIONS",
        contents: ["Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage à la pointe de la technologie pour protéger les informations sensibles transmises en ligne. Notre site internet possède bien entendu un Certificat SSL (Secure Socket Layer).",
        "Nous protégeons également vos informations hors ligne. Seuls les membres autorisés qui ont besoin d’effectuer un travail spécifique ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé par notre hébergeur.",
        ],
      },
      {
        num: 6,
        title: "COPIE OU SUPPRESSION DE VOS INFORMATIONS",
        contents: [
          "Nous stockons les données transmises via nos différents formulaires de contact.",
          "Cependant, vous avez la possibilité de nous contacter par e-mail à mail@discord-lafamilia.fr pour toute demande de copie ou de suppression de vos données personnelles que nous stockons.",
          
        ],
      },
      {
        num: 7,
        title: "POLITIQUE DES HEBERGEURS",
        contents: [
          "Nos hébergeurs possèdent leur propre politique de confidentialité concernant l'utilisation de vos données que vous pouvez consulter en tout temps:",
          "https://www.cloudflare.com/fr-fr/privacypolicy/",
          "https://pulseheberg.com/legal"
        ],
      },
      {
        num: 8,
        title: "CONSENTEMENT A NOTRE POLITIQUE",
        contents: [
          "En utilisant notre site, vous consentez à notre politique de confidentialité et que celle-ci puisse être modifiée en tout temps par l'éditeur.",
        ],
      },
      
    ],
  }),
};
</script>

<style lang="scss">
.cgu {
  margin: 0px auto;
  padding: 5%;
  padding-top: 30px;
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  h2 {
    padding-bottom: 10px;
    padding-top: 15px;
  }
  p {
    text-align: justify;
  }
}

@media only screen and (min-width: 960px) {
  .cgu {
    margin-left: 256px;
    h1 {
      font-size: 3em;
      margin-bottom: 20px;
    }
  }
}
</style>
