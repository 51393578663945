<template>
    <v-app>
        <NavBar></NavBar>
        <v-main>
            <router-view />
            <Footer />
        </v-main>
    </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

export default {
    name: "App",
    components: {
        NavBar,
        Footer,
    },
};
</script>

<style lang="scss"></style>
